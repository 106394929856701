<script>
import { postAction } from '@/command/netTool'

export default {
  mounted() {
    let aData = JSON.parse(localStorage.getItem('user_info'))
    const { id } = aData
    const { appId } = this.$route.query
    postAction('/api/admin/lkapply/isOpen', { appId: appId, userId: id }).then((e) => {
      const { code, data } = e
      if (code == 200) {
        if (data == '-1') {
          this.isShow = false
        } else if (data == '0') {
          this.isShow = true
        }
      }
    })
  },
  data() {
    return {
      isShow: false,
    }
  },
  methods: {
    applyOpening() {
      let aData = JSON.parse(localStorage.getItem('user_info'))
      const { id } = aData
      const { appId } = this.$route.query
      postAction('/api/admin/lkapply/save', { appId, userId: id }).then((res) => {
        const { code, data } = res
        if (code == 200) {
          if (data) {
            this.isShow = true
            this.$message.success('已提交申请开通，客服将尽快与您联系!')
          } else {
            this.$message.error('申请失败!')
          }
        }
      })
    },
    renderBody() {
      return (
        <div class="index-body">
          {this.isShow ? (
            <div
              class="index-btn-no"
              onClick={() => this.$message.success('已提交申请开通，客服将尽快与您联系!')}
            ></div>
          ) : (
            <div class="index-btn-yes" onClick={this.applyOpening}></div>
          )}
        </div>
      )
    },
    returnConsole() {
      this.$router.push('/index')
    },
    renderConsole() {
      /**
         * <a-button type="primary" onClick={this.returnConsole} class="consoleBox">
            <a-icon type="slack" class="consoleBox-icon" />
            控制台
          </a-button>
         */
      return <div class="index-console" onClick={this.returnConsole}></div>
    },
  },
  render() {
    const { appId } = this.$route.query
    return (
      <div class="index-box">
        <div class="index-main" style={{ backgroundImage: `url('${require(`./asset/${appId}.png`)}')` }}>
          {this.renderBody()}
          {this.renderConsole()}
        </div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.index-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}

.index-main {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  background-size: 100%;
  background-position: 0% 25%;
  background-repeat: no-repeat;
}
.index-console {
  width: 78px;
  height: 78px;
  background-image: url('./asset/back.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: absolute;
  left: 2.5%;
  top: 2.5%;
  cursor: pointer;

  .consoleBox {
    font-size: 16px;
    border-radius: 4px;
    margin-right: 20px;
    font-weight: bold;
    height: 40px;
    background-color: #2665fa;
    border: 0;

    .consoleBox-icon {
      font-size: 20px;
    }
  }
}
.index-body {
  width: 100%;
  position: absolute;
  bottom: 8%;
  left: 16.5%;

  .index-btn-no {
    width: 292px;
    height: 72px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('./asset/apply_no.png');
  }

  .index-btn-yes {
    width: 292px;
    height: 72px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url('./asset/apply_yes.png');
    cursor: pointer;
  }
}
</style>
